import styled from 'styled-components';
import React from 'react';

const PSButtom = styled.button`
  background: #fff;
  border: 2px solid #000;
  padding: 15px 20px;
  display: inline-block;
  text-align: center;
  color: #000;
  text-decoration: none;
  box-shadow: #000 10px 8px 0px;
  letter-spacing: 2px;
  font-size: 20px;
  min-width: 150px;
  text-align: center;
  font-family: 'roboto-mono';
  margin-top: 10px;
  &:hover {
    background: var(--yellow);
    box-shadow: none;
    color: var(--black);
  }
`

const Button = ({handleClick, text }) => {
  return (
    <PSButtom className="ps-btn-main" 
      onClick={handleClick}>{text}
    </PSButtom>
  )
}

export default Button;

